module.exports = [{
      plugin: require('C:/Users/Mutluhan/Desktop/dev/personal-web-page/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Mutluhan/Desktop/dev/personal-web-page/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Mutluhan/Desktop/dev/personal-web-page/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('C:/Users/Mutluhan/Desktop/dev/personal-web-page/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
